import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import Slider from "react-slick"
import { Row, Col, Container,Breadcrumb, Dropdown, Modal } from "react-bootstrap"
import BannerImg376 from "../../../images/static-banner376-img.jpg"
import BannerImg768 from "../../../images/static-banner768-img.jpg"
import BannerImg from "../../../images/static-banner-img.jpg"
import GoogleReview from "../../../images/google-reviews-white.svg"
import "../../MgServices/Breadcrumb/Breadcrumb.scss"
import "./StaticBanner.scss"
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"
import ShareImg from "../../../images/share-white.png";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import $ from "jquery"

import { GetURL } from "../../common/site/functions";
import Video from "../../Play/head-cta-video";
import { useLocation } from "@reach/router"
import ReactPlayer from 'react-player'
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";


import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2 
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 60 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const StaticBannerAreaGuideGGFX = (props) => {
  
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''


  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  const location = useLocation();
    
  const shareUrl = location.href


  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

  const image_url = props.Banner_Image.internal.description ? props.Banner_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 

  let processedImages = JSON.stringify({});
  if (props.data?.imagetransforms?.Banner_Image_Transforms) {
      processedImages = props.data.imagetransforms.Banner_Image_Transforms;
  }

  const title = props.Title ? props.Title.replace( /(<([^>]+)>)/ig, '') : ''

  const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);


 const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

}
const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
}
const showControls = () => {
    setVideoControls(true)
}

  return (
    <InView threshold={0}>
      {({ ref, inView }) => (
        <motion.div
          className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
            <div className="banner-item">
            {props.prop == 'no-image' ?

              <picture>
                <source media="(min-width:992px)" srcset={props.Banner_Image ? props.Banner_Image : BannerImg} />
                <source media="(min-width:768px)" srcset={props.Banner_Image ? props.Banner_Image : BannerImg768} />
                <motion.img variants={imageVariants} className="banner-img" src={props.Banner_Image ? props.Banner_Image : BannerImg376} alt={`${props.page} ${props.Title ? props.Title.replace( /(<([^>]+)>)/ig, '') : ''}`}></motion.img>
              </picture>
            :
              <picture>

                <ImageTransform imagesources={image_url} renderer="srcSet" imagename={props.img} attr={{ alt: title + ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={props.id}/>

              </picture>
            }
            
            <div className="banner-content mgservice-head">
                <Container>
                  <Row>
                    <Col xs={12} className="exp-btns">
                      <motion.div variants={titleVariants}>
                      {props.tag != "local-life" ? 
                      <Breadcrumbs url="area-guides" tag="contact" Title={props.Title}/> : <Breadcrumbs url={props.main_url == "main" ? "about" : "area-guides" } tag="local-main-landing" category={props.category} Title={props.Title.replace(/(<([^>]+)>)/gi, "").replace("&amp;","&").replace(" &amp;"," &").replace("&nbsp;","  ")}/> }
                        {props.tag != "local-life" ? <h1>{props.Title} Area Guide</h1> : '' }
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </div>

              
            
            <div className="banner-content mgservice-head area-banner-bottom-btns">
                <Container>
                  <Row>
                    <Col xs={12} className="exp-btns">
                      <motion.div variants={titleVariants}>
                        {/* <div className="btns-wrp d-md-flex align-items-center">
                          {props.Area_local_loving_life ? 
                            <Link to="local-loving-life" className="btn cta-1">Loving Local Life</Link> : ''
                          }


                        </div> */}
                        {props.tag != "local-life" ? 
                        <div className="share" onClick={openShareicons}>
                          <p><img loading="lazy" src={ShareImg} alt="" /><span>Share with a friend</span></p>
                          {Shareicons &&
                                  <div onClick={openShareicons} className="property-share-social-icons">
                                    <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                        <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                    </FacebookShareButton>
                                    <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                        <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                    </TwitterShareButton>
                                    <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                        <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                    </LinkedinShareButton>
                                    <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                        <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                    </WhatsappShareButton>
                                  </div>
                              }
                        </div> : '' }
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </div>

            {props.video &&
              <div className="area-guide-video-play-button">
                    <span className="area-guide-mobile-banner-play"  onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                        <i className="icon-play-transparent"></i>
                    </span>
              </div>
            }
              
            {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>

                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerShare(props.title)} url={props.video} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' light={true}/>
                 
                    </Modal.Body>
                </Modal>

            }
            </div>
        </motion.div>
      )}
    </InView>
  )
}

export default StaticBannerAreaGuideGGFX
